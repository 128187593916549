<template>
  <div class="w-full md:w-1/2 mx-auto text-center">
    <transition
      enter-active-class="miniFadeInUp"
      leave-active-class="miniFadeOutDown"
      mode="out-in"
    >
      <div v-if="!feedbackSubmitted" key="feedback">
        <div class="text-3xl font-merri mb-12 px-8  text-center ">
          We really don’t want to lose you.
        </div>
        <p>
          Is there any way we could keep you? (We’ll reply in the next 24 hrs)
        </p>
        <div class="w-full sm:w-2/3 mx-auto mb-8">
          <form @submit.prevent="submitFeedback">
            <textarea
              class="w-full mx-auto"
              v-model="feedback.stepOne"
              placeholder="Tell us here..."
            ></textarea>

            <loading-button
              class="mt-8"
              :is-loading="isLoading"
              :is-enabled="isEnabled"
              label="Submit feedback"
            />
          </form>
        </div>
        <div class="mt-24">
          <router-link
            class=" appearance-none  text-center text-white bg-red-500 text-white font-semibold rounded py-3 px-4 hover:bg-red-500 hover:text-white"
            :to="{
              name: 'cancel confirm',
              params: { subscription: subscription }
            }"
            >No thanks, continue with cancellation</router-link
          >
        </div>
      </div>
      <div v-else key="submitted">
        <div class="text-3xl font-merri mb-12 px-8  text-center ">
          Thanks for your feeback.
        </div>
        <p>We will be in touch shortly</p>
        <router-link
          class="mt-8 text-teal-400 underline"
          :to="{ name: 'dashboard' }"
          >Go to dashboard
          <font-awesome-icon :icon="['far', 'arrow-right-long']" class="ml-2"
        /></router-link>
      </div>
    </transition>
  </div>
</template>

<script>
import http from '@/http';
import LoadingButton from '@/components/LoadingButton';
export default {
  props: ['subscription', 'feedback'],
  created() {},
  data() {
    return {
      feedbackSubmitted: false,
      isLoading: false
    };
  },
  computed: {
    isEnabled: function() {
      if (this.feedback.stepOne) {
        return true;
      } else {
        return false;
      }
    }
  },
  components: {
    LoadingButton
  },
  methods: {
    submitFeedback: function() {
      this.isLoading = true;

      this.$store.dispatch('sendMpEvent', {
        event: 'Cancel feedback submitted',
        properties: {
          location: this.$route.name,
          feedback: this.feedback.stepOne,
          type: 'EVT'
        }
      });
      http
        .put(`/me/cancel_questions`, {
          questions: JSON.stringify(this.feedback)
        })

        .then(() => {
          this.feedbackSubmitted = true;
        })
        .catch(() => {
          this.error = true;
        });
    }
  }
};
</script>

<style lang="css" scoped></style>
